<template>
  <div class="login">
    <nav-menu></nav-menu>
    <el-row
      class="content"
      :gutter="24">
      <el-col
        :xs="0"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        class="login-left">
        <TransitionGroup
          appear
          tag="div"
          enter-active-class="animate__animated animate__bounceInLeft">
          <div
            class="left-bg"
            key="1"></div>
        </TransitionGroup>
      </el-col>
      <el-col
        :xs="24"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        class="login-right">
        <Transition
          appear
          enter-active-class="animate__animated animate__bounceInRight">
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
            class="login-form"
            auto-complete="on"
            label-position="top">
            <h2 class="title"></h2>
            <h3>登录</h3>

            <el-form-item
              label=""
              prop="email">
              <el-input
                v-model="loginForm.email"
                prefix-icon="el-icon-s-custom"></el-input>
            </el-form-item>
            <el-form-item
              label=""
              prop="passwd">
              <el-input
                v-model.trim="loginForm.passwd"
                show-password
                prefix-icon="el-icon-lock"></el-input>
            </el-form-item>
            <div class="forget">
              <router-link :to="{ path: '/forget', query: { type: 'forget' } }"
                >忘记密码</router-link
              >
              <span>|</span>
              <router-link to="/register">立即注册</router-link>
            </div>

            <el-button
              class="btns-default"
              @click="handelLogin"
              >登录</el-button
            >
          </el-form>
        </Transition>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import 'animate.css';
import { useUserStore } from '@/store/index';
import { isEmail } from '@/assets/js/validate';
import navMenu from '@/components/NavMenu';

export default {
  name: 'login',
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        this.sendDisabled = true;
        callback(new Error('请输入邮箱'));
      } else if (!isEmail(value)) {
        this.sendDisabled = true;
        callback(new Error('请输入正确格式的邮箱'));
      } else {
        this.sendDisabled = false;
        callback();
      }
    };
    return {
      loginForm: {
        email: '',
        passwd: '',
      },
      loginRules: {
        email: [{ required: true, validator: validateEmail, trigger: 'blur' }],
        passwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
    };
  },
  components: {
    navMenu,
  },
  methods: {
    handelLogin() {
      useUserStore()
        .login(this.loginForm)
        .then(() => {
          this.$router.push('/console/application');
        });
      //   login(this.loginForm).then((res) => {
      //     if (res.code === 200) {
      //       this.$message.success(res.message);
      //     }
      //   });
    },
  },
};
</script>

<style>
.login-right .el-input__prefix .el-input__icon {
  font-size: 25px;
  color: #5b03e4;
}
.login-right .el-input__inner {
  padding-left: 40px;
}
</style>
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .top-bg {
    margin-top: 40px;
    width: 100%;
    height: 70px;
    background: url(~@/assets/images/video-bg.jpg) no-repeat;
    background-size: cover;
    display: flex;
    padding: 0 60px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }
  .content {
    width: 100%;
    height: calc(100% - 90px);
    margin: 0 !important;
  }
  .login-left {
    padding-left: 0 !important;
    border-right: 1px solid #f3d7ff;
    height: 100%;
    div {
      height: 100%;
    }
  }
  .left-bg {
    background: url(~@/assets/images/services-left.jpg) no-repeat left center;
    background-size: 90%;
    width: 100%;
    height: 100%;
  }
  .login-right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .login-right::after {
    content: url(~@/assets/images/contact-left.jpg);
    bottom: 0px;
    right: 0;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    position: absolute;
    width: 103px;
    height: 464px;
    z-index: -1;
  }
  .login-form {
    width: 50%;
    .btns-default:hover {
      opacity: 0.8;
    }
    .el-form-item {
      margin-top: 40px;
    }
    .btns-default {
      font-size: 16px;
      width: 100%;
      margin: 30px 0;
      border-radius: 8px;
      background-color: #5b03e4;
      color: #fff;
    }
    .forget {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #f3d7ff;
      a {
        color: #5b03e4;
        font-size: 14px;
        padding: 0 10px;
      }
    }
  }
}
</style>
